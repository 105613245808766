import './certificateform.css'
const CertificateForm = ({modal}) => {
    const submitForm = (form)=>{
        form.preventDefault();
        console.log('Nice')
        modal(false)
    }
  return (
    <div className="modal__form">
        <form onSubmit={submitForm}>
            <div className="modal__form-input">
            <label htmlFor="Name">Name</label>
            <input defaultValue={''} type="text" name='name' placeholder='Name' />
            </div>
            <div className="modal__form-input">
            <label htmlFor="Issuing Organization">Issuing Organization</label>
            <input type="text" name='organization' placeholder='Organization' />
            </div>
            <div className="modal__form-input">
            <label htmlFor="Issue Data">Issue Date</label>
            <input type="text" name='issue_data' placeholder='Issue Date'/>
            </div>
            <div className="modal__form-input">
            <label htmlFor="Expiration">Expiration Date</label>
            <input type="text" name='expiration_date' placeholder='Expiration Date' />
            </div>
            <div className="modal__form-input">
            <label htmlFor="credential id">Credential ID</label>
            <input type="text" name='credential' placeholder='Credential ID' />
            </div>
            <div className="modal__form-input">
            <label htmlFor="credential url">Credential Url</label>
            <input type="text" name='credential' placeholder='Credential Url' />
            </div>
            <div className="modal__button">
            <button className='btn btn-primary' type='submit'>Save</button>
            </div>
        </form>
        </div>
  )
}

export default CertificateForm
