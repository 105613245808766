import Home from './screens/home/Home';
import Profile from './screens/profile/Profile'
import Login from './screens/login/Login'
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
const App = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/profile' element={<Profile/>}/>
          <Route path='/login' element={<Login/>} />
          <Route path='*' element={<h1>404: Not Found</h1>}/>
        </Routes>
      </Router>
    </>
  )
}

export default App
