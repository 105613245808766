import Back from '../../components/back/Back'
import Profiles from '../../components/profile/Profile'
import Modal from '../../components/certificates/modal/Modal'
import { useState } from 'react'
import CertificateForm from '../../components/certificateform/CertificateForm'
const Profile = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const modal = (value)=>{
    setModalOpen(modalOpen?false:true)
  }
  return (
    <div>
      <Back/>
      <Profiles
        modal={modal}
      />
      {
        modalOpen&&(
          <Modal
            component={<CertificateForm modal={modal}/>}
            title='License & Certificate'
            modal={modal} 
          />
        )
      }
    </div>
  )
}

export default Profile
