import './profile.css'
import bg from '../../assets/bg.svg'
import avatar1 from '../../assets/a.jpg'
import {TbExternalLink} from 'react-icons/tb'
import { useGetCertificatesQuery } from '../../state/api'
const education = [
  {
    school: 'University of Rizal System - Antipolo Campus',
    course: 'Bachelor of Science in Computer Engineering',
    year: '2019 - 2023'
  },
  {
    school: 'Antipolo City Senior High School',
    course: 'Computer Programming',
    year: '2017 - 2019'
  }
]
const Profile = ({modal}) => {
  const login = true
  
  const {data, isLoading, isError} = useGetCertificatesQuery();
  return (
    <>
      <div className="container profile__container">
        <section className='profile__section-1'>
          <div className="profile__card">
              <div className="profile__card-header">
                <img src={bg} alt="bg" className="profile__card__header__bg" />
              </div>
              <div className="profile__card-footer">
                <img src={avatar1} alt="avatar" className="profile__card__header__avatar" />
                <div className="profile__card-footer__name">
                  <h2>Kean Ulrich L. Gozon</h2>
                  <h5>Web Developer</h5>
                  <p>Antipolo City, Calabarzon, Philippines</p>
                </div>
              </div>
            </div>
            <div className='profile__about'>
              <h3>About</h3><br/>
              <div className="profile__content">
                  Knowledgeable in python<br/>Knowledgeable in HTML, CSS and JAVASCRIPT<br/>Knowledgeable in deploying web application in web server<br/>BASIC KNOWLEDGE in Web Security<br/>240 hrs experience in OJT in DATAMATICS cms Phils. as technical support and able to troubleshoot technical problems<br/>Knowledgeable in Networking Fundamentals and advance Networking Fundamentals<br/>National Certificate Holder Computer System Servicing II since 2019
              </div>
            </div>
            <div className='profile__about'>
              <div className="profile__content-header">
                <h3>Education</h3>
                <p onClick={()=>modal(true)}>Icon Add</p>
              </div>
              <br/>
              <div className="profile__content">
                {
                  isError?<p>Loading...</p>:
                  education.map(({school, course, year}, index) => {
                    return (
                        <div key={index}>
                          <h3>{school}</h3>
                          <p>{course}</p>
                          <p>{year}</p>
                          <hr />
                        </div>
                    )
                  })
                }
              </div>
            </div>
            <div className='profile__about'>
              <div className="profile__content-header">
              <h3>Licenses & certifications</h3>
              <p>Add Icon</p>
              </div>
              <br/>
              <div className="profile__content">
                {
                  isError?<p>Loading...</p>:
                  isLoading ? 'Loading': (
                    data.certificates.map(({issuing_organization, name,issue_date,expiration_date ,credential_url}, index) => {
                      return (
                        <div key={index}>
                        <div className="profile__licenses">
                            <div className="profile__licenses-icon">
                              <img src={issuing_organization[0].icon} alt={name} />
                            </div>
                            <div className="profile__licenses-content">
                              <h3>{name}</h3>
                              <p>{issuing_organization[0].name}</p>
                              <p>{expiration_date === '' ? issue_date:`${issue_date} - ${expiration_date}`}</p>
                              <div className="profile__show-cred">
                                <a href={credential_url} target='_blank' className='profile__credential' rel="noreferrer">
                                  Show credential <TbExternalLink/>
                                </a>
                              </div>
                            </div>
                        </div>
                        <hr />
                        </div>
                      )
                    })
                  )
                }
                
              </div>
            </div>
        </section>
        <section className='profile__section-2'>
          <div className="profile__underconstruction">
            My Previous Activity
          </div>
          <div className="profile__underconstruction">
            No Activity
          </div>
        </section>
      </div>
    </>
  )
}

export default Profile
