
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'
import {TfiFacebook} from 'react-icons/tfi'
const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href="https://www.linkedin.com/in/kean-ulrich-gozon-745241226" target="_blank" rel="noreferrer"><BsLinkedin/></a>
        <a href="https://github.com/creator0164" target="_black"><FaGithub/></a>
        <a href="https://www.facebook.com/profile.php?id=100003813997721" target="_blank" rel="noreferrer"><TfiFacebook/></a>
    </div>
  )
}

export default HeaderSocials
