import './modal.css'
const Modal = ({modal, title, component}) => {
  return (
    <div className="modal__main">
        <div className="modal__container">
            <div className="modal__card">
              <div className="modal__content-header">
                <div className='modal__close-title'>{title}</div>
                  <div className="modal__close">
                    <div onClick={()=>modal(false)} className="modal__close-icon">
                      <p>X</p>
                    </div>
                  </div>
              </div>
              <div className="modal__content">
                {component}
              </div>
            </div>
        </div>
    </div>
  )
}

export default Modal
