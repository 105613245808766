import 'swiper/css'
import 'swiper/css/pagination'
import './certificates.css'
import {Pagination} from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/react'
import {useGetCertificatesQuery} from '../../state/api'
import {TbExternalLink} from 'react-icons/tb'
const Certificates = () => {
  // const search = 'TESDA'
  // const url = `https://api.brandfetch.io/v2/search/${search}`
  // const options = {
  //   method: 'GET',
  //   headers: {
  //     accept: 'application/json',
  //   }
  // }
  // const fetchApi = async () =>{
  //   const response = await fetch(url, options)
  //   const data = await response.json()
  //   console.log(data)
  // }
  const {data, isLoading, isError} = useGetCertificatesQuery();
  return (
    <section id="certificates">
      <h5>My Achievements</h5>
      <h2>Certificates</h2>

      <Swiper className="container certificates__container"
      modules={[Pagination]}
      spaceBetween={40}
      slidesPerView={1}
      pagination={{clickable: true}}
      >
        {
          isError?<p>Loading...</p>:
          isLoading ? <h1>Loading...</h1> :(
            data.certificates.map(({issuing_organization, name, credential_url}, index)=>{
              return (
                <SwiperSlide key={index} className="certificate">
                <div className="client__avatar">
                  <img src={issuing_organization[0].icon} alt="Avatar One" />
                </div>
                <h4 className='client_name'>Issuing Organization: {issuing_organization[0].name}</h4>
                <h5 className='client_name'>Name: {name}</h5>
                <a href={credential_url} target='_blank' className='client__review' rel="noreferrer">
                  Show credential <TbExternalLink/>
                </a>
              </SwiperSlide>
              )
            }))
        }
        
      </Swiper>
    </section>
  )
}

export default Certificates
