import './back.css'
import {IoMdArrowRoundBack} from 'react-icons/io'
import {Link} from 'react-router-dom'
const Back = () => {
  return (
    <div className='back__icon'>
      <Link to='/'>
        <IoMdArrowRoundBack/>
      </Link>
    </div>
  )
}

export default Back
