import {createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const api = createApi({
    baseQuery: fetchBaseQuery({ 
      baseUrl: 'http://localhost:5001',
      headers: {
        Authorization: 'Bearer massytoken',
        'Content-Type': 'application/json'
      }
    }),
    reducerPath: 'adminApi',
    tagType: ['User'],
    endpoints: (builder) => ({
      sendEmail: builder.mutation({
        query: (data) => ({
          url: 'client/email_api',
          method: 'POST',
          body: data,
        }),
      }),
      getPosts: builder.query({
        query: ({page})=>({
          url: 'client/posts',
          method: 'GET',
          params: {page}
        })
      }),
      getCertificates: builder.query({
        query: ()=>'client/certificates'
      }),
      getExperiences: builder.query({
        query: ()=>'client/experiences'
      }),
      getAbout: builder.query({
        query: ()=> 'client/about'
      }),
      loginPost: builder.mutation({
        query: (data) => ({
          url: 'client/authenticate',
          method: 'POST',
          body: data,
        })
      }),
      authChecker: builder.query({
        query: (data)=> ({
          url: 'test',
          method: 'GET',
          headers: {
            Authorization: `Bearer ${data.token}`
          }
        }),
        
      })
    })
})
export const { useSendEmailMutation, useGetPostsQuery, useGetCertificatesQuery, useGetExperiencesQuery, useGetAboutQuery, useLoginPostMutation, useAuthCheckerQuery  } = api;