import { Link } from "react-router-dom"
const CTA = () => {
  return (
    <div className="cta">
      <Link to='/profile' className="btn">Profile</Link>
      <a className="btn btn-primary" href="#contact">Let's Talk</a>
    </div>
  )
}

export default CTA
