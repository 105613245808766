import './login.css'
import {BsEye, BsEyeSlash} from 'react-icons/bs';
import { useState } from 'react';
import { useLoginPostMutation, useAuthCheckerQuery } from '../../state/api';
import Cookies from 'universal-cookie';

const Login = () => {
  const cookies = new Cookies();
  const token = cookies.get('session_id')
  const auth = useAuthCheckerQuery({ token: token});
  console.log(auth)


  const [onOpen, setOnOpen] = useState(true)
  const setMode=()=>{
    setOnOpen(onOpen===true?false:true)
  }

  const [login, { data,isLoading, isSuccess }] = useLoginPostMutation();
  const handleSubmit = (e)=>{
    e.preventDefault();
    const datas = {
      account: e.target.account.value,
      password: e.target.password.value
    }
    login(datas)
    e.target.password.value=''
  }
  if(isSuccess){
    console.log(data)
    cookies.set('session_id', data.token, { path: '/', secure: true,expires: new Date(Date.now() + 60 * 60 * 1000)});
  }
  // const test = ()=>{
  //   cookies.remove('session_id')
  // }

  return (
    <div className="login__container">
      {/* <button onClick={test}>Submit</button> */}
      <div className="login__form">
        <form onSubmit={handleSubmit}>
          <label htmlFor="account">Account</label>
          <input  name='account' type="text" placeholder="Account" />
          <label htmlFor="password">Password</label>
          <div className="login__password">
            <input  name='password' type={onOpen ? 'password':'text'} placeholder="Password"/>
            <div onClick={setMode} className="login__password-icon">
              {
                onOpen ? (
                  <div className="login__password-icon-open">
                    <BsEye/>
                  </div>
                ):(
                  <div className="login__password-icon-close">
                    <BsEyeSlash/>
                  </div>
                )
              }
            </div>
          </div>
          <button type='submit' className='btn btn-primary'>Login</button>
        </form>
      </div>
    </div>
  )
}

export default Login
