import './portfolio.css'
import {useGetPostsQuery} from '../../state/api'
import { useState } from 'react'
const Portfolio = () => {
  const [page, setPage] = useState(0);
  const [newData, setNewData] = useState([]);
  const {data, isLoading,isError, refetch} = useGetPostsQuery({page:page})
  const d=(num)=>{
    refetch({page:num})
    setNewData([...newData, ...data.posts])
  }
  if (newData.length === 0 && !isLoading && !isError){
    setNewData([...newData, ...data.posts])
    setPage(page+6)
  }
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>
      <div className="container portfolio__container">
        {
          isError?<p>Loading...</p>:
          isLoading ? <p>Loading...</p>:(
            newData.map(({_id, thumbnail, headline, github_url, live_demo}) => {
            return (
              <article key={_id} className="portfolio__item">
              <div className="portfolio__item-image">
                <img src={thumbnail} alt={headline} />
              </div>
              <h3>{headline}</h3>
              <div className="portfolio__item-cta">
                <a href={github_url} className='btn' target='_blank' rel="noreferrer">Github</a>
                <a href={live_demo} className='btn btn-primary' target='_blank' rel="noreferrer">Live Demo</a>
              </div>
            </article>
            )
            }))
        }
      </div>
      {
        isError? '':!isLoading && data.total > 6 && data.total > page &&(
          <div className="portfolio__more">
          <a onClick={()=>{d(page);setPage(page+6)}} href='#!'>Load More</a>
      </div>
        )
      }
      
      
    </section>
  )
}

export default Portfolio
