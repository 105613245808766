import './experience.css'
import {BsPatchCheckFill} from 'react-icons/bs';
import {useGetExperiencesQuery} from '../../state/api'
const Experience = () => {
  const {data, isLoading, isError} = useGetExperiencesQuery();
  return (
    <section id="experience">
      <h5>What Skills I Have</h5>
      <h2>My Experience</h2>

      <div className="container experience__container">
        <div className="experience__frontend">
          <h3>Frontend Development</h3>
          <div className="experience__content">
            {isError?<p>Loading...</p>:isLoading? <p>Loading...</p>: (
              data.map(({_id, name, level, type})=>{
                return (
                  type && (
                    <article key={_id} className="experience__details">
                      <BsPatchCheckFill className='experience__details-icon'/>
                      <div>
                        <h4>{name}</h4>
                        <small className='text-light'>{level}</small>
                      </div>
                  </article>
                  )
                )
              })
            )}
          </div>
        </div>
        {/* End of frontend */}
        <div className="experience__backend">
          <h3>Backend Development</h3>
          <div className="experience__content">
            {isError?<p>Loading...</p>:isLoading? <p>Loading...</p>: (
              data.map(({_id, name, level, type})=>{
                return (
                  !type && (
                    <article key={_id} className="experience__details">
                      <BsPatchCheckFill className='experience__details-icon'/>
                      <div>
                        <h4>{name}</h4>
                        <small className='text-light'>{level}</small>
                      </div>
                    </article>
                  )
                )
              })
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Experience
